import React from 'react';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

interface EmptyTableStateProps {
  queryValue?: string;
  elementType: 'datasets' | 'concepts' | 'dynamic tags';
  primaryAction: React.ReactNode;
  hasSelectedFilters?: boolean;
  clearFilters?: () => void;
}

const EmptyTableState: React.FC<EmptyTableStateProps> = ({
  hasSelectedFilters,
  queryValue,
  elementType,
  primaryAction,
  clearFilters,
}) => {
  const hasFilters = hasSelectedFilters && clearFilters;

  const getMessage = (): string | undefined => {
    if (hasFilters && queryValue) {
      return `No ${elementType} match '${queryValue}' and filters`;
    }
    if (!hasFilters && queryValue) {
      return `No ${elementType} match '${queryValue}'`;
    }
    if (hasFilters && !queryValue) {
      return `No ${elementType} match your filters`;
    }
    return 'Nothing to see here (yet)';
  };

  const message = getMessage();

  return (
    <div className="text-center py-12 px-2 text-sm">
      <EmptyState
        primaryText={message}
        iconClassName="h-6 w-6 text-gray-400"
        Icon={ArchiveBoxXMarkIcon}
        callToAction={
          <>
            {primaryAction}
            {hasFilters && (
              <Button buttonStyle="text" onClick={clearFilters}>
                Clear filters
              </Button>
            )}
          </>
        }
      />
    </div>
  );
};

EmptyTableState.defaultProps = {
  queryValue: undefined,
  hasSelectedFilters: undefined,
  clearFilters: undefined,
};

export default EmptyTableState;
