import { Popover } from '@headlessui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import FilterGroup from './FilterGroup';
import { FilterConfiguration, FilterGroupType } from './types';

interface FiltersProps {
  filters: FilterConfiguration;
  setFilters: (filters: FilterConfiguration) => void;
  filterGroups: FilterGroupType[];
}

const getAddFilterButtonLabel = (
  filterCount: number,
  unsavedFilterCount: number,
) => {
  if (filterCount === 0)
    return unsavedFilterCount === 0
      ? 'Add filter'
      : `Add ${unsavedFilterCount === 0 ? '' : unsavedFilterCount} filter${
          unsavedFilterCount > 1 ? 's' : ''
        }`;
  return 'Update filters';
};

const Filters: React.FC<FiltersProps> = ({
  filters,
  setFilters,
  filterGroups,
}) => {
  const [unsavedFilters, setUnsavedFilters] =
    useState<FilterConfiguration>(filters);
  const [openGroupIdx, setOpenGroupIdx] = useState<number | null>(null);
  // Needed to calculate animating closing group
  const [pendingOpenGroupIdx, setPendingOpenGroupIdx] = useState<number | null>(
    null,
  );

  const hasNoChanges =
    JSON.stringify(filters) === JSON.stringify(unsavedFilters);

  const handleToggle = (idx: number) => {
    if (openGroupIdx === idx) {
      setOpenGroupIdx(null);
    } else if (openGroupIdx !== null) {
      setPendingOpenGroupIdx(idx);
      setOpenGroupIdx(null);
    } else {
      setOpenGroupIdx(idx);
    }
  };

  const filterCount = Object.values(filters).flat().length;
  const unsavedFilterCount = Object.values(unsavedFilters).flat().length;

  useEffect(() => {
    setUnsavedFilters(filters);
  }, [filters]);

  return (
    <Popover className="relative">
      {({ open, close }) => {
        // State needs to be handled this way, external open state doesn't work
        useEffect(() => {
          if (!open) {
            setOpenGroupIdx(null);
          }
        }, [open]);

        return (
          <>
            <Popover.Button className="focus:outline-none focus:ring-0 border-none">
              <Button
                textColor="text-green-700"
                className={classNames(
                  'bg-white text-green-700 hover:bg-gray-100 !shadow-none',
                )}
                color={open ? '!bg-gray-100 text-green-800' : undefined}
                onClick={() => null}
              >
                Filter {filterCount > 0 && `(${filterCount})`}
                <AdjustmentsHorizontalIcon className="ml-2 h-5 w-5 inline-block" />
              </Button>
            </Popover.Button>
            {open && (
              <Popover.Panel className="mt-1 shadow-sm w-96 absolute z-10 bg-white rounded-md pb-3 pt-1 border border-gray-100">
                <div className="py-2 px-4 space-y-4">
                  {filterGroups.map((filterGroup, idx) => (
                    <FilterGroup
                      isOpen={Boolean(openGroupIdx === idx)}
                      onToggle={() => handleToggle(idx)}
                      onAnimationEnd={() => {
                        if (pendingOpenGroupIdx !== null) {
                          setOpenGroupIdx(pendingOpenGroupIdx);
                          setPendingOpenGroupIdx(null);
                        }
                      }}
                      unsavedFilters={unsavedFilters}
                      setUnsavedFilters={setUnsavedFilters}
                      key={filterGroup.id}
                      filterGroup={filterGroup}
                    />
                  ))}
                </div>
                <div className="border-t border-gray-100 px-4 pt-3 text-end">
                  <Button
                    disabled={hasNoChanges}
                    onClick={() => {
                      setFilters(unsavedFilters);
                      close();
                    }}
                  >
                    {getAddFilterButtonLabel(filterCount, unsavedFilterCount)}
                  </Button>
                </div>
              </Popover.Panel>
            )}
          </>
        );
      }}
    </Popover>
  );
};

export default Filters;
