import { Column, DatasetTableRow } from 'api/generated';
import { DEFAULT_DATASET_FILTER_STATE } from 'components/Filter/constants';
import FadeTransition from 'components/FadeTransition';
import { FilterState } from 'components/Filter/types';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { getDatasetsQueryKey as getConceptDatasetsQueryKey } from 'pages/concepts/queries';
import CreateDatasetButton from 'pages/datasets/components/CreateDatasetButton';
import {
  invalidateDatasetQueries,
  useDeleteDatasetMutation,
} from 'queries/datasets';
import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';
import { SearchBarConfiguration } from 'components/SearchBar';
import EmptyTableState from 'components/Table/EmptyTableState';

interface TableViewProps {
  columns: Column[];
  datasets: DatasetTableRow[];
  total: number;
  refetchDatasets: () => void;
  loading?: boolean;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
  filterState: FilterState;
  querySearchConfiguration: SearchBarConfiguration;
}

const TableView: React.FunctionComponent<TableViewProps> = function TableView({
  columns,
  datasets,
  total,
  refetchDatasets,
  loading,
  pagination,
  setPageSize,
  loadPage,
  filterState,
  querySearchConfiguration,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteDataset } = useDeleteDatasetMutation();
  const loadingTable = useMemo(() => <LoadingTable />, []);

  const hasActiveFilters = Object.values(filterState.filters).flat().length > 0;
  const hasQueryValue = querySearchConfiguration?.value!.trim().length > 0;

  return (
    <>
      <FadeTransition show={Boolean(!loading && datasets)} appear>
        <div className="mt-2">
          <Table
            querySearchConfiguration={querySearchConfiguration}
            filterState={filterState}
            dataTestId="dataset"
            columns={columns}
            data={datasets as any}
            idKey="datasetId"
            actions={[
              {
                key: 'create',
                label: 'Create Concept',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) =>
                  navigate({
                    pathname: '/concepts/create',
                    search: `?datasetId=${row.data.datasetId}`,
                  }),
              },
              {
                key: 'delete',
                label: 'Delete Dataset',
                danger: true,
                dataTestId: 'delete-dataset',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) => {
                  deleteDataset(
                    { datasetId: row.data.datasetId },
                    {
                      onSuccess: () => {
                        refetchDatasets();
                      },
                      onError: () => {
                        toast.error(
                          `Unable to delete dataset ${row.data.name}. Try Again.`,
                        );
                      },
                    },
                  );
                  invalidateDatasetQueries(queryClient);
                  queryClient.invalidateQueries(getConceptDatasetsQueryKey());
                },
              },
            ]}
            total={total}
            emptyMessage={
              <EmptyTableState
                elementType="datasets"
                queryValue={
                  hasQueryValue ? querySearchConfiguration.value : undefined
                }
                hasSelectedFilters={hasActiveFilters}
                clearFilters={() =>
                  filterState.setFilters(DEFAULT_DATASET_FILTER_STATE)
                }
                primaryAction={<CreateDatasetButton buttonStyle="secondary" />}
              />
            }
            loadStrategy="paginated"
            activePage={pagination.page}
            pageSize={pagination.size}
            setPageSize={setPageSize}
            loadPage={loadPage}
          />
        </div>
      </FadeTransition>
      <FadeTransition
        show={Boolean(loading)}
        appear
        enterDelayMs={500}
        exit={false}
      >
        {!!loading && loadingTable}
      </FadeTransition>
    </>
  );
};

TableView.defaultProps = {
  loading: false,
};
export default TableView;
