import Checkbox from 'components/Checkbox';
import Disclosure from 'components/Disclosure';
import React from 'react';
import {
  FilterGroupType,
  FilterGroupComponent,
  FilterConfiguration,
} from './types';

const DISCLOSURE_CLASS_NAMES = {
  container: '!px-1 !py-1 border-0 !shadow-none',
  icon: 'w-[16px] h-[16px]',
  button: 'hover:bg-green-50 px-2 py-1 rounded-md',
};

interface FilterGroupProps {
  unsavedFilters: FilterConfiguration;
  setUnsavedFilters: (unsavedFilters: FilterConfiguration) => void;
  filterGroup: FilterGroupType;
  onToggle: () => void;
  isOpen?: boolean;
  onAnimationEnd?: () => void;
}

const FilterGroup: React.FC<FilterGroupProps> = ({
  unsavedFilters,
  setUnsavedFilters,
  filterGroup,
  isOpen,
  onToggle,
  onAnimationEnd,
}) => {
  const handleSelectMultiFilter = (value: string) => {
    const filteredOptions = unsavedFilters[filterGroup.id].filter(
      (filterOption) => filterOption !== value,
    );

    setUnsavedFilters({
      ...unsavedFilters,
      [filterGroup.id]: unsavedFilters[filterGroup.id].includes(value)
        ? filteredOptions
        : [...unsavedFilters[filterGroup.id], value],
    });
  };

  const handleSelectSingleFilter = (value: string) => {
    setUnsavedFilters({
      ...unsavedFilters,
      [filterGroup.id]: unsavedFilters[filterGroup.id].includes(value)
        ? []
        : [value],
    });
  };

  const renderFilters = () => {
    switch (filterGroup.component) {
      case FilterGroupComponent.SingleSelect:
        return filterGroup.filters.map((filterOption) => (
          <Checkbox
            onChange={() => handleSelectSingleFilter(filterOption.value)}
            key={`${filterGroup.id}-${filterOption.value}`}
            name={filterOption.name}
            label={filterOption.name}
            id={`${filterGroup.id}-${filterOption.value}`}
            checked={unsavedFilters[filterGroup.id].includes(
              filterOption.value,
            )}
          />
        ));
      default:
        return filterGroup.filters.map((filterOption) => (
          <Checkbox
            onChange={() => handleSelectMultiFilter(filterOption.value)}
            key={`${filterGroup.id}-${filterOption.value}`}
            name={filterOption.name}
            label={filterOption.name}
            id={`${filterGroup.id}-${filterOption.value}`}
            checked={unsavedFilters[filterGroup.id].includes(
              filterOption.value,
            )}
          />
        ));
    }
  };

  return (
    <Disclosure
      onToggle={onToggle}
      onAnimationEnd={onAnimationEnd}
      key={filterGroup.id}
      shouldOpen={isOpen}
      className={DISCLOSURE_CLASS_NAMES}
      title={`${filterGroup.name} ${
        unsavedFilters[filterGroup.id].length > 0
          ? `(${unsavedFilters[filterGroup.id].length})`
          : ''
      }`}
    >
      {renderFilters()}
    </Disclosure>
  );
};

FilterGroup.defaultProps = {
  isOpen: undefined,
  onAnimationEnd: undefined,
};
export default FilterGroup;
