import { DatasetStatusEnum } from 'api/generated';

export const DATASET_FILTERS = [
  {
    id: 'status',
    name: 'STATUS',
    filters: [
      { name: 'Ready', value: DatasetStatusEnum.Ready },
      { name: 'Initializing', value: DatasetStatusEnum.Initializing },
      { name: 'Waiting', value: DatasetStatusEnum.Waiting },
      { name: 'Incomplete', value: DatasetStatusEnum.Incomplete },
      { name: 'Error', value: DatasetStatusEnum.Error },
      { name: 'Deleting', value: DatasetStatusEnum.Deleting },
    ],
  },
];

export const DEFAULT_DATASET_FILTER_STATE = DATASET_FILTERS.reduce(
  (acc, curr) => {
    acc[curr.id] = [];
    return acc;
  },
  {},
);
